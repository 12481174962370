import React, { useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Toolbar,
  Link,
  Button,
  Box,
  InputBase,
  ClickAwayListener,
  Typography,
  Grid,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Badge,
  Container,
  Fade,
  Stack,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const MyBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    color: "#000",
    backgroundColor: "#fff",
    minWidth: "16px",
    height: "16px",
    fontSize: "10px",
    p: 0,
  },
}));

const MyIconButton = styled(IconButton)(({ theme }) => ({
  minWidth: "unset",
  fontSize: 20,
  color: "#000",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

const SearchIconWrapper = styled(Button)(({ theme }) => ({
  padding: theme.spacing(0, 1),
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: "unset",
  margin: "7px 0px",
  fontSize: "16px",
  color: "#fff",
  zIndex: "1",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: "4px 4px 4px 32px",
    color: "#fff",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function MainAppbar(props) {
  const { component } = props;
  const { openMenu, setOpenMenu } = component.props;

  const {
    wishlist = [],
    order = null,
    mainMenu = [],
    coupons = [],
  } = component.getData("default", {});

  const isAuth = component.isAuthenticated();

  const theme = useTheme();

  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("new_files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [searchForm, setSearchForm] = useState(false);
  const inputRef = useRef(null);

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [menu, setMenu] = useState("new-in");

  const [menuEffect, setMenuEffect] = useState(false);

  const [womensImage, setWomensImage] = React.useState("women-rings");
  const [mensImage, setMensImage] = React.useState("men-rings");

  const [imageSpeed, setImageSpeed] = React.useState(0);

  const newIn = mainMenu.find((menu) => menu.slug === "new-in");
  const womens = mainMenu.find((menu) => menu.slug === "womens");
  const mens = mainMenu.find((menu) => menu.slug === "mens");
  const gifts = mainMenu.find((menu) => menu.slug === "gifts");

  const handleToggle = (boolean, new_menu) => {
    setOpenMenu(boolean);
    setWomensImage("women-rings");
    setMensImage("men-rings");
    setImageSpeed(0);
    if (new_menu !== undefined) {
      setMenu(new_menu);
    }
  };

  const handleToggleAccountInfo = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  React.useEffect(() => {
    if (mainMenu.length) {
      setTimeout(() => {
        setMenuEffect(true);
      }, 500);
    }
  }, [mainMenu.length]);

  const handleSearchClick = () => {
    setSearchForm(!searchForm);
  };

  return (
    <>
      <Toolbar disableGutters>
        <Container maxWidth="xl" disableGutters>
          <Stack
            direction="row"
            sx={{
              px: 2,
              justifyContent: "space-between",
              alignItems: "center",
              height: {
                xs: theme.appbar_height.mobile,
                md: theme.appbar_height.desktop,
              },
            }}
          >
            <Stack
              direction="row"
              spacing={0}
              sx={{
                display: { xs: "flex", md: "none" },
                width: { xs: 110, md: 400 },
              }}
            >
              <MyIconButton
                disableRipple
                onClick={() => {
                  component.toggleMenuDrawer();
                }}
                aria-label="Mobile Menu"
              >
                <i className="fa-regular fa-bars" />
              </MyIconButton>
              <MyIconButton
                disableRipple
                onClick={() => handleSearchClick()}
                sx={{
                  visibility: searchForm ? "hidden" : "visible",
                  display: { xs: "block", md: "none" },
                }}
                aria-label="Search"
              >
                <i className="fa-regular fa-magnifying-glass" />
              </MyIconButton>
            </Stack>
            <Stack
              direction="row"
              spacing={0}
              sx={{
                display: { xs: "none", md: "flex" },
                justifyContent: "flex-start",
                alignItems: "stretch",
                width: 400,
                height: "100%",
              }}
            >
              {mainMenu.map((menu, index) => {
                const { slug, name, url } = menu;
                return (
                  <Box
                    key={`menu-btn-${slug}`}
                    onMouseEnter={() => {
                      if (menuEffect) {
                        handleToggle(true, slug);
                      }
                    }}
                    onMouseLeave={() => handleToggle(false, slug)}
                    onClick={() => handleToggle(false, slug)}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      pl: index === 0 ? 0 : 1.5,
                      pr: index === mainMenu.length ? 0 : 1.5,
                      "&:hover,&:focus": {
                        "& a": {
                          borderBottom: "2px solid #000",
                        },
                      },
                    }}
                  >
                    <Button
                      to={url}
                      component={RouterLink}
                      key={`main-menu-root-${slug}`}
                      sx={{
                        color: "#000",
                        textTransform: "none",
                        fontSize: 14,
                        lineHeight: 1,
                        p: 0,
                        fontFamily: "Spartan-Bold",
                        borderBottom: "2px solid transparent",
                        borderRadius: 0,
                        "&:hover,&:focus": {
                          backgroundColor: "transparent",
                        },
                      }}
                      disableRipple
                    >
                      {name}
                    </Button>
                  </Box>
                );
              })}
            </Stack>
            <Button
              sx={{
                outline: "none",
                p: 0,
                "&:hover": {
                  bgcolor: "transparent",
                },
                img: { width: { xs: 75, md: 95 } },
              }}
              disableRipple
              component={RouterLink}
              to="/"
            >
              <img
                src={`${filesUrl}/logos/black/square.png?tr=w-350,v=${img_version}`}
                srcSet={`${filesUrl}/logos/black/square.png?tr=w-300,v=${img_version} 600w, ${filesUrl}/logos/black/square.png?tr=w-350,v=${img_version} 900w`}
                sizes="(max-width: 600px) 300px, (max-width: 900px) 350px"
                alt="Konstantino Home"
                title="Konstantino Home"
              />
            </Button>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                justifyContent: "flex-end",
                alignItems: "stretch",
                width: { xs: 110, md: 400 },
                height: "100%",
              }}
            >
              <MyIconButton
                disableRipple
                onClick={() => handleSearchClick()}
                sx={{
                  visibility: searchForm ? "hidden" : "visible",
                  display: { xs: "none", md: "block" },
                }}
                aria-label="Search"
              >
                <i className="fa-regular fa-magnifying-glass" />
              </MyIconButton>
              <MyIconButton
                disableRipple
                component={RouterLink}
                to="/wishlist"
                aria-label="Wishlist"
              >
                <MyBadge
                  badgeContent={wishlist.length}
                  showZero
                  sx={{
                    "& .MuiBadge-badge": {
                      visibility: wishlist.length === 0 ? "hidden" : "visible",
                    },
                  }}
                >
                  <i className="fa-regular fa-heart" />
                </MyBadge>
              </MyIconButton>
              <MyIconButton
                disableRipple
                component={RouterLink}
                to="/cart"
                onMouseEnter={() => component.toggleCartPopper(true)}
                onMouseLeave={() => component.toggleCartPopper(false)}
                aria-label="Cart"
              >
                <MyBadge
                  badgeContent={devHelper.getObjectValue(
                    order,
                    "amounts.items",
                    0
                  )}
                  showZero
                  sx={{
                    "& .MuiBadge-badge": {
                      visibility:
                        devHelper.getObjectValue(order, "amounts.items", 0) ===
                        0
                          ? "hidden"
                          : "visible",
                    },
                  }}
                >
                  <i className="fa-regular fa-bag-shopping"></i>
                </MyBadge>
              </MyIconButton>
              {isDesktop && (
                <>
                  {!isAuth ? (
                    <MyIconButton
                      component={RouterLink}
                      to="/signin"
                      disableRipple
                      aria-label="User Account"
                    >
                      <i className="fa-regular fa-user"></i>
                    </MyIconButton>
                  ) : (
                    <>
                      <MyIconButton
                        ref={anchorRef}
                        aria-controls={open ? "menu-list-grow" : undefined}
                        aria-haspopup="true"
                        onClick={handleToggleAccountInfo}
                        disableRipple
                        aria-label="User Account"
                      >
                        <MyBadge
                          badgeContent={coupons.length}
                          showZero
                          sx={{
                            "& .MuiBadge-badge": {
                              visibility:
                                coupons.length === 0 ? "hidden" : "visible",
                            },
                          }}
                        >
                          <i className="fa-regular fa-user"></i>
                        </MyBadge>
                      </MyIconButton>
                      <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === "bottom"
                                  ? "center top"
                                  : "center bottom",
                            }}
                          >
                            <Paper square>
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                  autoFocusItem={open}
                                  id="menu-list-grow"
                                  onKeyDown={handleListKeyDown}
                                >
                                  <MenuItem
                                    component={RouterLink}
                                    to="/profile"
                                    onClick={handleClose}
                                    sx={{
                                      fontFamily:
                                        "founders-grotesk,Helvetica,sans-serif",
                                      zIndex: 1000000,
                                    }}
                                  >
                                    {component.ucfirst("k-profile")}
                                  </MenuItem>
                                  <MenuItem
                                    component={RouterLink}
                                    to="/addresses"
                                    onClick={handleClose}
                                    sx={{
                                      fontFamily:
                                        "founders-grotesk,Helvetica,sans-serif",
                                    }}
                                  >
                                    {component.ucfirst("k-addresses")}
                                  </MenuItem>
                                  <MenuItem
                                    component={RouterLink}
                                    to="/orders"
                                    onClick={handleClose}
                                    sx={{
                                      fontFamily:
                                        "founders-grotesk,Helvetica,sans-serif",
                                    }}
                                  >
                                    {component.ucfirst("k-orders")}
                                  </MenuItem>
                                  <MenuItem
                                    component={RouterLink}
                                    to="/coupons"
                                    onClick={handleClose}
                                    sx={{
                                      fontFamily:
                                        "founders-grotesk,Helvetica,sans-serif",
                                    }}
                                  >
                                    <MyBadge
                                      badgeContent={coupons.length}
                                      sx={{
                                        "& .MuiBadge-badge": {
                                          right: -15,
                                          top: 12,
                                          background: "#000",
                                          color: "#fff",
                                        },
                                      }}
                                    >
                                      {component.ucfirst("k-coupons")}
                                    </MyBadge>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => component.getPage().logout()}
                                    sx={{
                                      fontFamily:
                                        "founders-grotesk,Helvetica,sans-serif",
                                    }}
                                  >
                                    {component.ucfirst("logout")}
                                  </MenuItem>
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </>
                  )}
                </>
              )}
            </Stack>
          </Stack>
        </Container>
      </Toolbar>
      {searchForm && (
        <ClickAwayListener onClickAway={() => setSearchForm(false)}>
          <Container
            maxWidth="xl"
            disableGutters
            sx={{ backgroundColor: "transparent", mb: 1 }}
          >
            <form
              onSubmit={(e) => component.checkSearch(e)}
              method="get"
              style={{
                width: "100%",
                padding: "0px 10px",
                position: "relative",
              }}
            >
              <Stack direction="row">
                <SearchIconWrapper
                  type="submit"
                  sx={{
                    left: { xs: "15px", md: "23px" },
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                  disableRipple
                  aria-label="Search"
                >
                  <i className="fa-regular fa-magnifying-glass"></i>
                </SearchIconWrapper>
                <StyledInputBase
                  sx={{ marginLeft: { xs: "15px", md: "23px" } }}
                  placeholder="Search…"
                  value={component.getData("default.search_term", "")}
                  onChange={(e) => {
                    component.setData({
                      "default.search_term": e.target.value,
                    });
                  }}
                  autoFocus
                  inputRef={inputRef}
                  inputProps={{ "aria-label": "search" }}
                />
                <Button
                  type="submit"
                  sx={{
                    color: "#fff",
                    border: "1px solid #fff",
                    px: 1.5,
                    py: 0,
                    minWidth: 0,
                    minHeight: 0,
                    fontSize: 12,
                    "&:hover": { border: "1px solid #fff" },
                  }}
                  variant="outlined"
                >
                  Explore
                </Button>
              </Stack>
            </form>
          </Container>
        </ClickAwayListener>
      )}
      <Fade in={openMenu} timeout={{ enter: 500, exit: 0 }}>
        <Box
          sx={{ position: "relative", display: { xs: "none", md: "block" } }}
        >
          <Box
            onMouseEnter={() => {
              handleToggle(true);
            }}
            onMouseLeave={() => {
              handleToggle(false);
            }}
            sx={{
              m: 0,
              position: "absolute",
              left: 0,
              zIndex: 3000,
              width: "100%",
              backgroundColor: "#fff",
            }}
          >
            <Container maxWidth="xl">
              {menu === "new-in" && (
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  sx={{ py: 2, justifyContent: "center" }}
                >
                  {devHelper
                    .getObjectValue(newIn, "subMenu.curation", [])
                    .map((data) => {
                      const { slug, title = "", button = "", url } = data;

                      return (
                        <Grid item xs={6} key={`new-in-${slug}`}>
                          <Box
                            key={slug}
                            sx={{
                              textAlign: "center",
                              "&:hover": {
                                "& > div > a > img": {
                                  transform: "scale(1.3)",
                                  webkitTransform: "scale(1.3)",
                                },
                              },
                            }}
                          >
                            <Box
                              className="box-img-zoomed"
                              sx={{
                                overflow: "hidden",
                                position: "relative",
                              }}
                            >
                              <Link
                                component={RouterLink}
                                to={url}
                                onClick={() => handleToggle(false)}
                                sx={{
                                  img: {
                                    width: "100%",
                                    height: "auto",
                                    transition: "transform .5s ease",
                                    webkitTransition: "transform .5s ease",
                                  },
                                }}
                              >
                                <img
                                  src={`${filesUrl}/marketing/menu/new-in/${slug}.jpg?tr=w-1500`}
                                  alt={title}
                                />
                              </Link>
                              <Stack
                                spacing={0.5}
                                sx={{
                                  position: "absolute",
                                  bottom: 0,
                                  left: 0,
                                  pl: 3,
                                  pb: 3,
                                  alignItems: "flex-start",
                                }}
                              >
                                <Link
                                  component={RouterLink}
                                  to={url}
                                  variant="text"
                                  sx={{
                                    fontFamily: "Spartan-Bold",
                                    lineHeight: 1,
                                    fontSize: 18,
                                    textDecoration: "none",
                                    color: "#fff",
                                  }}
                                  onClick={() => handleToggle(false)}
                                >
                                  {title}
                                </Link>
                                <Button
                                  component={RouterLink}
                                  to={url}
                                  sx={{
                                    color: "#fff",
                                    fontSize: 13,
                                    borderBottom: "1px solid #fff",
                                    borderRadius: 0,
                                    fontWeight: 600,
                                    lineHeight: 1,
                                    p: 0,
                                    "&:hover,&:focus": {
                                      backgroundColor: "transparent",
                                      borderBottom: "1px solid transparent",
                                    },
                                  }}
                                  disableRipple
                                >
                                  {button}
                                </Button>
                              </Stack>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                </Grid>
              )}
              {menu === "womens" && (
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  sx={{ py: 2, justifyContent: "center" }}
                >
                  <Grid item xs={5.5}>
                    <Box sx={{ position: "relative" }}>
                      {Object.entries(
                        devHelper.getObjectValue(womens, "subMenu", {})
                      ).map(([key, values], index) => {
                        return (
                          <React.Fragment key={key}>
                            {values.map((value, i) => {
                              const { slug, title = "", url } = value;

                              if (slug.includes("_")) {
                                return null;
                              }
                              return (
                                <Fade
                                  key={key + slug}
                                  in={womensImage === slug}
                                  timeout={
                                    `${index}${i}` === "00" ? imageSpeed : 1000
                                  }
                                >
                                  <Link
                                    component={RouterLink}
                                    to={url}
                                    sx={{
                                      img: {
                                        width: "100%",
                                        height: "auto",
                                        transition: "transform .5s ease",
                                        webkitTransition: "transform .5s ease",
                                        position:
                                          `${index}${i}` === "00"
                                            ? "relative"
                                            : "absolute",
                                        top: 0,
                                        left: 0,
                                        zIndex: 0,
                                      },
                                    }}
                                  >
                                    <img
                                      src={`${filesUrl}/marketing/menu/wide/${slug}.jpg?tr=w-1500,h-1000,c-maintain_ratio,v=${img_version}`}
                                      alt={title}
                                      onLoad={() => {
                                        `${index}${i}` === "00" &&
                                          setImageSpeed(1000);
                                      }}
                                    />
                                  </Link>
                                </Fade>
                              );
                            })}
                          </React.Fragment>
                        );
                      })}
                    </Box>
                  </Grid>
                  <Grid item xs={6.5}>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      sx={{ justifyContent: "center" }}
                    >
                      {Object.entries(
                        devHelper.getObjectValue(womens, "subMenu", {})
                      ).map(([key, value]) => {
                        return (
                          <Grid item xs={3} key={key}>
                            <Typography
                              align="center"
                              sx={{
                                fontFamily: "Poppins-SemiBold",
                                color: "#000",
                                fontSize: 22,
                                textTransform: "uppercase",
                                mb: 2,
                              }}
                            >
                              {key}
                            </Typography>
                            <Stack spacing={2}>
                              {value.map((val) => {
                                const { slug, title = "", url } = val;
                                return (
                                  <Link
                                    key={`${key}-${slug}`}
                                    component={RouterLink}
                                    align="center"
                                    to={url}
                                    onClick={() => handleToggle(false)}
                                    onMouseEnter={() => {
                                      !slug.includes("_") &&
                                        setWomensImage(slug);
                                    }}
                                    sx={{
                                      fontFamily: "Spartan-Bold",
                                      color: "#000",
                                      fontSize: 13,
                                      textTransform: "uppercase",
                                      textDecoration: "none",
                                    }}
                                  >
                                    {title}
                                  </Link>
                                );
                              })}
                            </Stack>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {menu === "mens" && (
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  sx={{ py: 2, justifyContent: "center" }}
                >
                  <Grid item xs={5.5}>
                    <Box sx={{ position: "relative" }}>
                      {Object.entries(
                        devHelper.getObjectValue(mens, "subMenu", {})
                      ).map(([key, values], index) => {
                        return (
                          <React.Fragment key={key}>
                            {values.map((value, i) => {
                              const { slug, title = "", url } = value;

                              if (slug.includes("_")) {
                                return null;
                              }
                              return (
                                <Fade
                                  key={key + slug}
                                  in={mensImage === slug}
                                  timeout={
                                    `${index}${i}` === "00" ? imageSpeed : 1000
                                  }
                                >
                                  <Link
                                    component={RouterLink}
                                    to={url}
                                    sx={{
                                      img: {
                                        width: "100%",
                                        height: "auto",
                                        transition: "transform .5s ease",
                                        webkitTransition: "transform .5s ease",
                                        position:
                                          `${index}${i}` === "00"
                                            ? "relative"
                                            : "absolute",
                                        top: 0,
                                        left: 0,
                                        zIndex: 0,
                                      },
                                    }}
                                  >
                                    <img
                                      src={`${filesUrl}/marketing/menu/wide/${slug}.jpg?tr=w-1500,h-1000,c-maintain_ratio,v=${img_version}`}
                                      alt={title}
                                      onLoad={() => {
                                        `${index}${i}` === "00" &&
                                          setImageSpeed(1000);
                                      }}
                                    />
                                  </Link>
                                </Fade>
                              );
                            })}
                          </React.Fragment>
                        );
                      })}
                    </Box>
                  </Grid>
                  <Grid item xs={6.5}>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      sx={{ justifyContent: "center" }}
                    >
                      {Object.entries(
                        devHelper.getObjectValue(mens, "subMenu", {})
                      ).map(([key, value]) => {
                        return (
                          <Grid item xs={3} key={key}>
                            <Typography
                              align="center"
                              sx={{
                                fontFamily: "Poppins-SemiBold",
                                color: "#000",
                                fontSize: 22,
                                textTransform: "uppercase",
                                mb: 2,
                              }}
                            >
                              {key}
                            </Typography>
                            <Stack spacing={2}>
                              {value.map((val) => {
                                const { slug, title = "", url } = val;
                                return (
                                  <Link
                                    key={`${key}-${slug}`}
                                    component={RouterLink}
                                    align="center"
                                    to={url}
                                    onClick={() => handleToggle(false)}
                                    onMouseEnter={() => {
                                      !slug.includes("_") && setMensImage(slug);
                                    }}
                                    sx={{
                                      fontFamily: "Spartan-Bold",
                                      color: "#000",
                                      fontSize: 13,
                                      textTransform: "uppercase",
                                      textDecoration: "none",
                                    }}
                                  >
                                    {title}
                                  </Link>
                                );
                              })}
                            </Stack>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {menu === "gifts" && (
                <Grid
                  container
                  direction="row"
                  spacing={1}
                  sx={{ py: 2, justifyContent: "center" }}
                >
                  <Grid item xs={2.5}>
                    <Box
                      sx={{
                        textAlign: "center",
                        "&:hover": {
                          "& > div > a > img": {
                            transform: "scale(1.3)",
                            webkitTransform: "scale(1.3)",
                          },
                        },
                      }}
                    >
                      <Box
                        className="box-img-zoomed"
                        sx={{
                          overflow: "hidden",
                          position: "relative",
                        }}
                      >
                        <Link
                          component={RouterLink}
                          to="/gifts"
                          onClick={() => handleToggle(false)}
                          sx={{
                            img: {
                              width: "100%",
                              height: "auto",
                              transition: "transform .5s ease",
                              webkitTransition: "transform .5s ease",
                            },
                          }}
                        >
                          <img
                            src={`${filesUrl}/marketing/menu/gifts/womens-gifts.jpg?tr=w-1000,v=${img_version}`}
                            alt="WOMEN’S GIFTS"
                          />
                        </Link>
                        <Stack
                          spacing={0.5}
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            pl: 3,
                            pb: 3,
                            alignItems: "flex-start",
                          }}
                        >
                          <Link
                            component={RouterLink}
                            to="/gifts"
                            variant="text"
                            sx={{
                              fontFamily: "Spartan-Bold",
                              lineHeight: 1,
                              fontSize: 18,
                              textDecoration: "none",
                              color: "#fff",
                            }}
                            onClick={() => handleToggle(false)}
                          >
                            WOMEN’S GIFTS
                          </Link>
                          <Button
                            component={RouterLink}
                            to="/gifts"
                            sx={{
                              color: "#fff",
                              fontSize: 13,
                              borderBottom: "1px solid #fff",
                              borderRadius: 0,
                              fontWeight: 600,
                              lineHeight: 1,
                              p: 0,
                              minWidth: 0,
                              "&:hover,&:focus": {
                                backgroundColor: "transparent",
                                borderBottom: "1px solid transparent",
                              },
                            }}
                            disableRipple
                          >
                            EXPLORE
                          </Button>
                        </Stack>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={3.5}>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      sx={{ justifyContent: "center" }}
                    >
                      {Object.entries(
                        devHelper.getObjectValue(gifts, "womensMenu", {})
                      ).map(([key, value]) => {
                        return (
                          <Grid item xs={4} key={key}>
                            <Typography
                              align="center"
                              sx={{
                                fontFamily: "Poppins-SemiBold",
                                color: "#000",
                                fontSize: 22,
                                textTransform: "uppercase",
                                mb: 2,
                              }}
                            >
                              {key}
                            </Typography>
                            <Stack spacing={2}>
                              {value.map((val) => {
                                const { slug, title = "", url } = val;
                                return (
                                  <Link
                                    key={`${key}-${slug}`}
                                    component={RouterLink}
                                    align="center"
                                    to={url}
                                    onClick={() => handleToggle(false)}
                                    onMouseEnter={() => {
                                      !slug.includes("_") && setMensImage(slug);
                                    }}
                                    sx={{
                                      fontFamily: "Spartan-Bold",
                                      color: "#000",
                                      fontSize: 13,
                                      textTransform: "uppercase",
                                      textDecoration: "none",
                                    }}
                                  >
                                    {title}
                                  </Link>
                                );
                              })}
                            </Stack>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                  <Grid item xs={2.5}>
                    <Box
                      sx={{
                        textAlign: "center",
                        "&:hover": {
                          "& > div > a > img": {
                            transform: "scale(1.3)",
                            webkitTransform: "scale(1.3)",
                          },
                        },
                      }}
                    >
                      <Box
                        className="box-img-zoomed"
                        sx={{
                          overflow: "hidden",
                          position: "relative",
                        }}
                      >
                        <Link
                          component={RouterLink}
                          to="/gifts"
                          onClick={() => handleToggle(false)}
                          sx={{
                            img: {
                              width: "100%",
                              height: "auto",
                              transition: "transform .5s ease",
                              webkitTransition: "transform .5s ease",
                            },
                          }}
                        >
                          <img
                            src={`${filesUrl}/marketing/menu/gifts/mens-gifts.jpg?tr=w-1000,v=${img_version}`}
                            alt="MEN’S GIFTS"
                          />
                        </Link>
                        <Stack
                          spacing={0.5}
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            pl: 3,
                            pb: 3,
                            alignItems: "flex-start",
                          }}
                        >
                          <Link
                            component={RouterLink}
                            to="/gifts"
                            variant="text"
                            sx={{
                              fontFamily: "Spartan-Bold",
                              lineHeight: 1,
                              fontSize: 18,
                              textDecoration: "none",
                              color: "#fff",
                            }}
                            onClick={() => handleToggle(false)}
                          >
                            MEN’S GIFTS
                          </Link>
                          <Button
                            component={RouterLink}
                            to="/gifts"
                            sx={{
                              color: "#fff",
                              fontSize: 13,
                              borderBottom: "1px solid #fff",
                              borderRadius: 0,
                              fontWeight: 600,
                              lineHeight: 1,
                              p: 0,
                              minWidth: 0,
                              "&:hover,&:focus": {
                                backgroundColor: "transparent",
                                borderBottom: "1px solid transparent",
                              },
                            }}
                            disableRipple
                          >
                            EXPLORE
                          </Button>
                        </Stack>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={3.5}>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      sx={{ justifyContent: "center" }}
                    >
                      {Object.entries(
                        devHelper.getObjectValue(gifts, "mensMenu", {})
                      ).map(([key, value]) => {
                        return (
                          <Grid item xs={4} key={key}>
                            <Typography
                              align="center"
                              sx={{
                                fontFamily: "Poppins-SemiBold",
                                color: "#000",
                                fontSize: 22,
                                textTransform: "uppercase",
                                mb: 2,
                              }}
                            >
                              {key}
                            </Typography>
                            <Stack spacing={2}>
                              {value.map((val) => {
                                const { slug, title = "", url } = val;
                                return (
                                  <Link
                                    key={`${key}-${slug}`}
                                    component={RouterLink}
                                    align="center"
                                    to={url}
                                    onClick={() => handleToggle(false)}
                                    onMouseEnter={() => {
                                      !slug.includes("_") && setMensImage(slug);
                                    }}
                                    sx={{
                                      fontFamily: "Spartan-Bold",
                                      color: "#000",
                                      fontSize: 13,
                                      textTransform: "uppercase",
                                      textDecoration: "none",
                                    }}
                                  >
                                    {title}
                                  </Link>
                                );
                              })}
                            </Stack>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Container>
            <Box
              sx={{
                bgcolor: "#B5B6AD",
                py: 2,
              }}
            >
              <Container maxWidth="xl" disableGutters>
                <Stack
                  direction="row"
                  spacing={6}
                  sx={{
                    justifyContent: "flex-end",
                    "& a": {
                      color: "#fff",
                      fontSize: "16px",
                      borderBottom: "2px solid transparent",
                      borderRadius: 0,
                      fontWeight: 600,
                      lineHeight: 1,
                      p: 0,
                      "&:hover,&:focus": {
                        backgroundColor: "transparent",
                        borderBottom: "2px solid #fff",
                      },
                    },
                  }}
                >
                  <Button to="/contact-us" component={RouterLink} disableRipple>
                    CONTACT US
                  </Button>
                  <Button to="/boutiques" component={RouterLink} disableRipple>
                    STORE LOCATOR
                  </Button>
                  <Button
                    to="/trunk-shows"
                    component={RouterLink}
                    disableRipple
                  >
                    ANNOUCMENTS
                  </Button>
                </Stack>
              </Container>
            </Box>
          </Box>
        </Box>
      </Fade>
    </>
  );
}
