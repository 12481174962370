import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/navbar/main-appbar-test";
import Services from "../../Services/Services";

export default class Navbar extends Component {
  id = "navbar";
  group = "wishlist,order";

  mainMenu = [
    {
      slug: "new-in",
      name: "NEW IN",
      url: "/women/curation/new-arrivals",
      subMenu: {
        curation: [
          {
            slug: "womens-new-arrivals",
            title: "NEW WOMEN’S",
            button: "EXPLORE ALL",
            url: "/women/curation/new-arrivals",
          },
          {
            slug: "mens-new-arrivals",
            title: "NEW MEN’S",
            button: "EXPLORE ALL",
            url: "/men/curation/new-arrivals",
          },
        ],
      },
    },
    {
      slug: "womens",
      name: "WOMEN'S",
      url: "/women",
      subMenu: {
        category: [
          {
            slug: "women-rings",
            title: "Rings",
            url: "/women/type/ring",
          },
          {
            slug: "women-bracelets",
            title: "Bracelets",
            url: "/women/type/bracelet",
          },
          {
            slug: "women-pendants",
            title: "Pendants & Charms",
            url: "/women/type/pendant",
          },
          {
            slug: "women-earrings",
            title: "Earrings",
            url: "/women/type/earrings",
          },
          {
            slug: "women-necklaces",
            title: "Necklaces",
            url: "/women/type/necklace",
          },
          {
            slug: "shop-all-jewelry_",
            title: "Shop All Jewelry",
            url: "/women",
          },
        ],
        material: [
          {
            slug: "women-gold",
            title: "Gold",
            url: "/women/metal/18kt-gold",
          },
          {
            slug: "women-mixed-metals",
            title: "Mixed Metals",
            url: "/women/metal/mixed-metals",
          },
          {
            slug: "women-sterling-silver",
            title: "Sterling Silver",
            url: "/women/metal/sterling-silver",
          },
          {
            slug: "women-leather",
            title: "Leather",
            url: "/women/metal/sterling-silver",
          },
        ],
        featured: [
          {
            slug: "women-best-sellers",
            title: "Best Sellers",
            url: "/women/curation/best-sellers",
          },
          {
            slug: "women-new-arrivals",
            title: "New In",
            url: "/women/curation/new-arrivals",
          },
          {
            slug: "women-crosses",
            title: "Crosses",
            url: "/women/type/cross",
          },
          {
            slug: "women-seasonal-edit",
            title: "Seasonal Edit",
            url: "/women/curation/seasonal-edit",
          },
          {
            slug: "women-novelties",
            title: "Novelties",
            url: "/women/curation/women-novelties",
          },
          {
            slug: "birthstones",
            title: "Birth Stones",
            url: "/women/collection/birthstone",
          },
          {
            slug: "zodiacs",
            title: "Zodiacs",
            url: "/women/collection/zodiac",
          },
        ],
        collections: [
          {
            slug: "view-all_",
            title: "View All",
            url: "/women/collections",
          },
        ],
      },
    },
    {
      slug: "mens",
      name: "MEN'S",
      url: "/men",
      subMenu: {
        category: [
          {
            slug: "men-rings",
            title: "Rings",
            url: "/men/type/ring",
          },
          {
            slug: "men-bracelets",
            title: "Bracelets",
            url: "/men/type/bracelet",
          },
          {
            slug: "men-pendants",
            title: "Pendants & Talismans",
            url: "/men/type/pendant",
          },
          {
            slug: "men-necklaces",
            title: "Necklaces & Chains",
            url: "/men/type/necklace",
          },
          {
            slug: "men-cufflinks",
            title: "Cufflinks",
            url: "/men/type/cufflinks",
          },
        ],
        material: [
          {
            slug: "men-gold",
            title: "Gold",
            url: "/men/metal/18kt-gold",
          },
          {
            slug: "men-mixed-metals",
            title: "Mixed Metals",
            url: "/men/metal/mixed-metals",
          },
          {
            slug: "men-sterling-silver",
            title: "Sterling Silver",
            url: "/men/metal/sterling-silver",
          },
          {
            slug: "men-leather",
            title: "Leather",
            url: "/men/curation/leather-designs",
          },
        ],
        featured: [
          {
            slug: "men-best-sellers",
            title: "Best Sellers",
            url: "/men/curation/best-sellers",
            position: 3,
          },
          {
            slug: "men-new-in",
            title: "New In",
            url: "/men/curation/new-arrivals",
          },
          {
            slug: "men-crosses",
            title: "Crosses",
            url: "/men/curation/cross",
          },
          {
            slug: "men-seasonal-edit",
            title: "Seasonal Edit",
            url: "/men/curation/seasonal-edit",
          },
          {
            slug: "birthstones",
            title: "Birth Stones",
            url: "/women/collection/birthstone",
          },
          {
            slug: "zodiacs",
            title: "Zodiacs",
            url: "/women/collection/zodiac",
          },
        ],
        collections: [
          {
            slug: "view-all",
            title: "View All",
            url: "/men/collections",
          },
        ],
      },
    },
    {
      slug: "gifts",
      name: "GIFTS",
      url: "/gifts",
      menu_template: "gifts",
      womensMenu: {
        price: [
          {
            slug: "gifts-under-500",
            title: "Under $500",
            type: "womensgifts",
            url: "/women/gift/under-500",
          },
          {
            slug: "gifts-under-1000",
            title: "Under $1000",
            url: "/women/gift/under-1000",
          },
          {
            slug: "gifts-under-2000",
            title: "Under $2000",
            url: "/women/gift/under-2000",
          },
          {
            slug: "luxe-gifts",
            title: "Luxe Gifts",
            url: "/",
          },
        ],
        featured: [
          {
            slug: "women-best-sellers",
            title: "Best Sellers",
            url: "/women/curation/best-sellers",
          },
          {
            slug: "women-new-arrivals",
            title: "New In",
            url: "/women/curation/new-arrivals",
          },
          {
            slug: "women-crosses",
            title: "Crosses",
            url: "/women/type/cross",
          },
          {
            slug: "women-seasonal-edit",
            title: "Seasonal Edit",
            url: "/women/curation/seasonal-edit",
          },
          {
            slug: "women-novelties",
            title: "Novelties",
            url: "/women/curation/women-novelties",
          },
          {
            slug: "birthstones",
            title: "Birth Stones",
            url: "/women/collection/birthstone",
          },
          {
            slug: "zodiacs",
            title: "Zodiacs",
            url: "/women/collection/zodiac",
          },
        ],
        material: [
          {
            slug: "women-gold",
            title: "Gold",
            url: "/women/metal/18kt-gold",
          },
          {
            slug: "women-mixed-metals",
            title: "Mixed Metals",
            url: "/women/metal/mixed-metals",
          },
          {
            slug: "women-sterling-silver",
            title: "Sterling Silver",
            url: "/women/metal/sterling-silver",
          },
          {
            slug: "women-leather",
            title: "Leather",
            url: "/women/metal/sterling-silver",
          },
        ],
      },
      mensMenu: {
        price: [
          {
            slug: "gifts-under-500",
            title: "Under $500",
            type: "womensgifts",
            url: "/men/gift/under-500",
          },
          {
            slug: "gifts-under-1000",
            title: "Under $1000",
            url: "/men/gift/under-1000",
          },
          {
            slug: "gifts-under-2000",
            title: "Under $2000",
            url: "/men/gift/under-2000",
          },
          {
            slug: "luxe-gifts",
            title: "Luxe Gifts",
            url: "/",
          },
        ],
        featured: [
          {
            slug: "men-best-sellers",
            title: "Best Sellers",
            url: "/men/curation/best-sellers",
            position: 3,
          },
          {
            slug: "men-new-in",
            title: "New In",
            url: "/men/curation/new-arrivals",
          },
          {
            slug: "men-crosses",
            title: "Crosses",
            url: "/men/curation/cross",
          },
          {
            slug: "men-seasonal-edit",
            title: "Seasonal Edit",
            url: "/men/curation/seasonal-edit",
          },
          {
            slug: "birthstones",
            title: "Birth Stones",
            url: "/women/collection/birthstone",
          },
          {
            slug: "zodiacs",
            title: "Zodiacs",
            url: "/women/collection/zodiac",
          },
        ],
        material: [
          {
            slug: "men-gold",
            title: "Gold",
            url: "/men/metal/18kt-gold",
          },
          {
            slug: "men-mixed-metals",
            title: "Mixed Metals",
            url: "/men/metal/mixed-metals",
          },
          {
            slug: "men-sterling-silver",
            title: "Sterling Silver",
            url: "/men/metal/sterling-silver",
          },
          {
            slug: "men-leather",
            title: "Leather",
            url: "/men/curation/leather-designs",
          },
        ],
      },
    },
  ];

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getOrder();
    this.getMenu();
    this.checkWishlist();
    if (this.isAuthenticated()) {
      this.retrieveCoupons();
    }

    const errorOrderMessage = this.getHelpers("const").get(
      "error-order-message"
    );

    if (errorOrderMessage) {
      this.getHelpers("const").set("error-order-message", "");
      this.setData({ "error.order-message": errorOrderMessage });
    }
  }

  getMenu() {
    // Services.get("menu").then(([menuService]) => {
    //   menuService
    //     .getAll()
    //     .then((menuService) => {
    // this.setData({ "default.mainMenu": menuService.getData("menu", []) });
    this.setData({ "default.mainMenu": this.mainMenu });
    //     })
    //     .catch((menuService) => {});
    // });
  }

  getOrder() {
    Services.get("order").then(([orderService]) => {
      orderService
        .fetchOrder()
        .then((orderService) => {
          const order = orderService.getData("order");
          this.setData({ "default.order": order });
        })
        .catch((orderService) => {});
    });
  }

  checkWishlist() {
    Services.get("wishlist").then(([wishlistService]) => {
      var wishlist = wishlistService.getWishlist();
      this.setData({ "default.wishlist": wishlist });
    });
  }

  retrieveCoupons() {
    Services.get("coupon").then(([couponService]) => {
      couponService
        .getCoupons()
        .then((couponService) => {
          const coupons = couponService.getData("coupons", []);

          this.setData({
            "default.coupons": coupons,
          });
        })
        .catch((err) => {
          console.log("regvfcdinj");
          console.log("error", err);
        });
    });
  }

  toggleMenuDrawer() {
    this.getComponents()
      .findById("menu-drawer")
      .forEach((drawer) => {
        drawer.toggle();
      });
  }

  toggleCartPopper(boolean) {
    this.getComponents().findById("cart-popper").first().setPopperOpen(boolean);
  }

  checkSearch(e) {
    e.preventDefault();
    const { search_term } = this.getData("default", null);
    const devHelper = this.getHelpers("env").getPublicUrl();
    if (search_term && search_term.length > 1) {
      window.location.href = devHelper + "/search/results?term=" + search_term;
    }
  }
}
