import React from "react";
import { styled } from "@mui/material/styles";
import {
  Container,
  Typography,
  Grid,
  Box,
  CircularProgress,
  Link,
  Autocomplete,
  InputAdornment,
  TextField,
  Stack,
} from "@mui/material";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import ClearIcon from "@mui/icons-material/Clear";

const MyAutocomplete = styled(Autocomplete)(({ theme }) => ({
  color: "#fff",
  [`& .${autocompleteClasses.popupIndicator}`]: {
    transform: "none",
  },
  "& .MuiFormControl-root": {
    "& .MuiFormLabel-root": {
      color: "#fff",
      fontSize: "18px",
      fontFamily: "Poppins-Regular",
    },
    "& .MuiInputBase-root": {
      borderColor: "#fff",
      color: "#fff",
    },
    "& .MuiInputBase-root:after": {
      display: "none",
    },

    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
      color: "#fff",
      visibility: "visible",
    },
  },
}));

const MyMobileDatePicker = styled(MobileDatePicker)(({ theme }) => ({
  "& .MuiFormLabel-root": {
    color: "#fff",
    fontSize: "18px",
    fontFamily: "Poppins-Regular",
  },
  "& .MuiInputBase-root": {
    borderColor: "#fff",
    color: "#fff",
  },
  "& .MuiInputBase-root:after": {
    display: "none",
  },

  "& .MuiInput-underline:before": {
    display: "none",
  },
  "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
    color: "#fff",
    visibility: "visible",
  },
  "& label.Mui-focused": {
    color: "#fff",
  },
}));

export default function TrunkShows(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("new_files_url");

  const {
    trunkshows = null,
    stores = [],
    locations = [],
    date = null,
    location = null,
    store = null,
  } = component.getData("default", {});

  const months = [
    component.ucfirst("January"),
    component.ucfirst("February"),
    component.ucfirst("March"),
    component.ucfirst("April"),
    component.ucfirst("May"),
    component.ucfirst("June"),
    component.ucfirst("July"),
    component.ucfirst("August"),
    component.ucfirst("September"),
    component.ucfirst("October"),
    component.ucfirst("November"),
    component.ucfirst("December"),
  ];

  const changeDate = (newValue) => {
    component.setData({
      "default.date": component
        .getHelpers("date-time")
        .createDate(new Date(newValue)),
    });
    component.setTrunkShows();
  };

  const changeLocation = (newValue) => {
    component.setData({ "default.location": newValue });
    component.setTrunkShows();
  };

  const changeStore = (newValue) => {
    component.setData({ "default.store": newValue });
    component.setTrunkShows();
  };

  return (
    <Container
      sx={{
        px: 4,
        pb: 6,
        minHeight: 1000,
      }}
      disableGutters
      maxWidth="xl"
    >
      <img
        src={`${filesUrl}/marketing/locations-backround.jpg?tr=w-400`}
        srcSet={`${filesUrl}/marketing/locations-backround.jpg?tr=w-400 400w,
        ${filesUrl}/marketing/locations-backround.jpg?tr=w-800 800w,
        ${filesUrl}/marketing/locations-backround.jpg?tr=w-1200 1200w,
        ${filesUrl}/marketing/locations-backround.jpg?tr=w-1600 1600w,
        ${filesUrl}/marketing/locations-backround.jpg?tr=w-2000 2000w`}
        sizes="100vw"
        alt="Background"
        style={{
          width: "100vw",
          height: "100vh",
          objectFit: "cover",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: -2,
        }}
      />
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: -1,
          bgcolor: "#000",
          opacity: 0.4,
        }}
      />
      <Box sx={{ textAlign: "center", mt: "150px" }}>
        <Typography
          variant="h1"
          sx={{
            fontSize: 26,
            fontFamily: "Poppins-Bold",
            color: "#fff",
          }}
        >
          TRUNK SHOW ANNOUNCEMENTS
        </Typography>
        <Typography
          variant="h2"
          sx={{
            fontSize: 16,
            fontFamily: "Spartan-Medium",
            color: "#fff",
            mx: "auto",
            mt: "4px",
          }}
        >
          All the designs are handpicked, piece by piece,
          <br />
          by founder and creator Konstantino.
        </Typography>
      </Box>
      <Grid
        container
        sx={{
          flexGrow: 1,
          alignItems: "center",
          mt: 3,
        }}
        direction="row"
        rowSpacing={1}
        columnSpacing={{ xs: 0, sm: "4px" }}
      >
        <Grid
          item
          xs={12}
          sm={4}
          justifycontent="center"
          sx={{
            textAlign: "center",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MyMobileDatePicker
              label="FIND BY DATE"
              inputFormat="MM/DD/YYYY"
              value={date}
              onChange={changeDate}
              popupIcon={
                <i
                  className="fa-light fa-location-dot"
                  style={{ color: "#fff" }}
                />
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  sx={{
                    m: 1,
                    maxWidth: "160px",
                    "& .MuiFormLabel-root": {
                      fontSize: 18,
                      fontFamily: "Poppins-Bold !important",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <>
                          <ClearIcon
                            sx={{
                              color: "#fff",
                              visibility: date ? "visible" : "hidden",
                              cursor: date ? "pointer" : "none",
                              mr: "4px",
                            }}
                            fontSize="small"
                            onClick={() => {
                              component.setData({ "default.date": null });
                              component.setTrunkShows();
                            }}
                          />
                          <i
                            className="fa-solid fa-calendar-days"
                            style={{
                              color: "#fff",
                              fontSize: "22px",
                              cursor: "pointer",
                            }}
                          ></i>
                        </>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          justifycontent="center"
          sx={{
            textAlign: "center",
          }}
        >
          <MyAutocomplete
            value={location}
            onChange={(event, newValue) => {
              changeLocation(newValue);
            }}
            options={locations}
            popupIcon={
              <i
                className="fa-solid fa-location-dot"
                style={{ color: "#fff", fontSize: "22px" }}
              />
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="FIND BY LOCATION"
                variant="standard"
                sx={{
                  m: 1,
                  maxWidth: "200px",
                  "& .MuiFormLabel-root": {
                    fontSize: 18,
                    fontFamily: "Poppins-Bold !important",
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          justifycontent="center"
          sx={{
            textAlign: "center",
          }}
        >
          <MyAutocomplete
            value={store}
            onChange={(event, newValue) => {
              changeStore(newValue);
            }}
            options={stores}
            popupIcon={
              <i
                className="fa-solid fa-shop"
                style={{ color: "#fff", fontSize: "22px" }}
              />
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="FIND BY STORE"
                variant="standard"
                sx={{
                  m: 1,
                  maxWidth: "176px",
                  "& .MuiFormLabel-root": {
                    fontSize: 18,
                    fontFamily: "Poppins-Bold !important",
                  },
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          width: "100%",
          mb: 3,
          borderBottom:
            trunkshows && trunkshows.length !== 0 ? "none" : "1px solid #fff",
          borderTop: "1px solid #fff",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "142.69px",
        }}
      >
        {!trunkshows ? (
          <CircularProgress
            sx={{
              color: "#fff",
            }}
          />
        ) : (
          <>
            {trunkshows.length !== 0 ? (
              <Grid
                container
                sx={{
                  flexGrow: 1,
                  alignItems: "stretch",
                  m: 0,
                }}
                direction="row"
                rowSpacing={3}
                columnSpacing={0}
              >
                {trunkshows.map((show) => {
                  const {
                    slug,
                    name,
                    address_text_1 = null,
                    address_text_2 = null,
                    url = null,
                    phone = "",
                    title = null,
                    date_from = null,
                    date_to = null,
                    city = null,
                    extra_info = null,
                  } = devHelper.getObjectValue(show, "_values");

                  var new_phone = "",
                    number = "",
                    country_num = "";

                  if (phone.trim().length >= 10) {
                    number = phone.slice(-10);
                    country_num = phone.slice(0, -10);
                    new_phone =
                      (country_num && country_num + "-") +
                      number.slice(0, 3) +
                      "-" +
                      number.slice(3, 6) +
                      "-" +
                      number.slice(-4);
                  }

                  return (
                    <Grid
                      item
                      xs={12}
                      justifycontent="center"
                      sx={{
                        textAlign: "center",
                        pb: 3,
                        borderBottom: "1px solid #fff",
                      }}
                      key={"show-" + slug}
                    >
                      <Stack spacing={1}>
                        <Typography
                          sx={{
                            fontFamily: "Poppins-Bold",
                            color: "#fff",
                            fontSize: 20,
                            lineHeight: 1.2,
                          }}
                        >
                          {name + " - " + city}
                        </Typography>
                        {title && (
                          <Typography
                            sx={{
                              fontFamily: "Spartan-SemiBold",
                              color: "#fff",
                              fontSize: 14,
                              lineHeight: 1.2,
                            }}
                          >
                            {title}
                          </Typography>
                        )}
                        <Stack spacing={0.2}>
                          {date_from && (
                            <Typography
                              sx={{
                                fontFamily: "Spartan-Bold",
                                color: "#fff",
                                fontSize: 14,
                                lineHeight: 1.2,
                              }}
                            >
                              {`${months[date_from.split("-")[1] - 1]} ${
                                date_from.split("-")[2]
                              }`}
                              {date_from !== date_to &&
                                ` -  ${date_to.split("-")[2]}`}
                            </Typography>
                          )}
                          {extra_info && (
                            <Typography
                              sx={{
                                fontFamily: "Spartan-Bold",
                                color: "#fff",
                                fontSize: 14,
                                lineHeight: 1.2,
                              }}
                            >
                              {extra_info}
                            </Typography>
                          )}
                        </Stack>
                        {url ? (
                          <Link
                            href={url}
                            rel="noreferrer"
                            target="_blank"
                            underline="none"
                            sx={{
                              fontFamily: "Spartan-SemiBold",
                              color: "#fff",
                              fontSize: 14,
                              lineHeight: 1.2,
                              display: "inline-block",
                            }}
                          >
                            {address_text_1 && address_text_1}
                            <br />
                            {address_text_2 && address_text_2}
                          </Link>
                        ) : (
                          <Link
                            underline="none"
                            sx={{
                              fontFamily: "Spartan-SemiBold",
                              color: "#fff",
                              fontSize: 14,
                              lineHeight: 1.2,
                              display: "inline-block",
                            }}
                          >
                            {address_text_1 && address_text_1}
                            <br />
                            {address_text_2 && address_text_2}
                          </Link>
                        )}

                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{
                            justifyContent: "center",
                            alignItems: "center",
                            "& *": {
                              fontFamily: "Spartan-SemiBold",
                              color: "#fff",
                              fontSize: 14,
                              lineHeight: 1.2,
                            },
                            "& a": {
                              color: "#fff",
                            },
                          }}
                        >
                          {phone && (
                            <>
                              <Box component="span">Tel:</Box>
                              <Link href={"tel:" + phone} underline="none">
                                {new_phone}
                              </Link>
                            </>
                          )}
                        </Stack>
                      </Stack>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Box
                sx={{
                  textAlign: "center",
                }}
              >
                <Typography sx={{ color: "#fff" }}>
                  NO TRUNK SHOW FOUND
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
    </Container>
  );
}
