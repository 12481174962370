import React from "react";
import { styled } from "@mui/material/styles";
import {
  Container,
  Typography,
  Grid,
  Box,
  Link,
  Button,
  Stack,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins-Bold",
  color: "#fff",
  fontSize: 20,
  lineHeight: 1.2,
}));

export default function Boutiques(props) {
  const { component } = props;

  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("new_files_url");

  const mainMenu = component.getData("default.mainMenu", []);
  const klocations = mainMenu.find((menu) => menu.slug === "locations");

  return (
    <Container
      sx={{
        px: 4,
        pb: 6,
        minHeight: 1000,
      }}
      disableGutters
      maxWidth="xl"
    >
      <img
        src={`${filesUrl}/marketing/locations-backround.jpg?tr=w-400`}
        srcSet={`${filesUrl}/marketing/locations-backround.jpg?tr=w-400 400w,
        ${filesUrl}/marketing/locations-backround.jpg?tr=w-800 800w,
        ${filesUrl}/marketing/locations-backround.jpg?tr=w-1200 1200w,
        ${filesUrl}/marketing/locations-backround.jpg?tr=w-1600 1600w,
        ${filesUrl}/marketing/locations-backround.jpg?tr=w-2000 2000w`}
        sizes="100vw"
        alt="Background"
        style={{
          width: "100vw",
          height: "100vh",
          objectFit: "cover",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: -2,
        }}
      />
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: -1,
          bgcolor: "#000",
          opacity: 0.4,
        }}
      />
      <Box sx={{ textAlign: "center", mt: "150px" }}>
        <Title
          variant="h1"
          sx={{
            fontSize: 26,
            fontFamily: "Poppins-Bold",
            color: "#fff",
          }}
        >
          WHERE YOU CAN FIND US
        </Title>
        <Typography
          variant="h2"
          sx={{
            fontSize: 16,
            fontFamily: "Spartan-Medium",
            color: "#fff",
            mx: "auto",
            mt: "4px",
          }}
        >
          Tap the county of your choice below to find us in a store near you!
        </Typography>
      </Box>
      <Container
        sx={{
          mt: 3,
          py: 6,
          borderBottom: "1px solid #fff",
          borderTop: "1px solid #fff",
          display: "flex",
          justifyContent: "center",
        }}
        maxWidth="xl"
      >
        <Grid container direction="row" spacing={2} maxWidth="md">
          {devHelper
            .getObjectValue(klocations, "subMenu.location", [])
            .map((data) => {
              const { slug, title, url } = data;

              return (
                <Grid
                  key={"klocations-" + slug}
                  item
                  xs={6}
                  sm={4}
                  md={2.4}
                  justifycontent="center"
                >
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      height: 0,
                      paddingBottom: "100%",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        component={RouterLink}
                        to={url}
                        sx={{
                          width: "100%",
                          height: "100%",
                          color: "#fff",
                          bgcolor: "transparent",
                          textAlign: "center",
                          border: "2px solid #fff",
                          borderRadius: "0px",
                          fontSize: 20,
                          lineHeight: 1,
                          fontWeight: 600,
                          "&:hover,&:focus": {
                            bgcolor: "#B5B6AD",
                          },
                        }}
                      >
                        {title}
                      </Button>
                    </div>
                  </div>
                </Grid>
              );
            })}
        </Grid>
      </Container>
      <Box sx={{ textAlign: "center", mt: 4 }}>
        <Title
          variant="h1"
          sx={{
            fontSize: 26,
            fontFamily: "Poppins-Bold",
            color: "#fff",
          }}
        >
          OUR BOUTIQUES &amp; CONTACT INFORMATION
        </Title>
        <Typography
          variant="h2"
          sx={{
            fontSize: 16,
            fontFamily: "Spartan-Medium",
            color: "#fff",
            mt: 1,
            mb: 4,
          }}
        >
          We invite you to shop our jewelry creations at one of our
          <br /> Greek boutiques in Athens and Mykonos.
        </Typography>
      </Box>
      <Grid
        container
        justifycontent="center"
        direction="row"
        justify="center"
        sx={{ px: { xs: 0, md: 8 }, mb: 4 }}
        rowSpacing={{ xs: 3, md: 0 }}
      >
        <Grid
          item
          md={4}
          xs={12}
          justifycontent="center"
          sx={{
            textAlign: "center",
          }}
        >
          <Title>KONSTANTINO ATHENS (Flagship Store)</Title>
          <Link
            href="https://www.google.com/maps/place/KONSTANTINO+JEWELRY+-+ATHENS/@37.9756587,23.7276433,19.63z/data=!4m5!3m4!1s0x14a1bd3d320b57ad:0x62993108934b74eb!8m2!3d37.9756326!4d23.7278967"
            rel="noreferrer"
            target="_blank"
            underline="none"
            sx={{
              fontFamily: "Spartan-SemiBold",
              color: "#fff",
              fontSize: 14,
              lineHeight: 1.2,
              display: "inline-block",
              my: 1,
            }}
          >
            20, Pandrossou Str. Plaka
            <br />
            ATHENS, GREECE
          </Link>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              "& *": {
                fontFamily: "Spartan-SemiBold",
                color: "#fff",
                fontSize: 14,
                lineHeight: 1.2,
              },
              "& a": {
                color: "#fff",
              },
            }}
          >
            <Box component="span">Tel:</Box>
            <Link href="tel:+302103222701" underline="none">
              +30 210.322.2701
            </Link>
            <Box component="span">Email:</Box>
            <Link
              href="mailto:konstantino.gr@gmail.com"
              target="_blank"
              underline="none"
            >
              K-Athens
            </Link>
          </Stack>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          justifycontent="center"
          sx={{
            textAlign: "center",
          }}
        >
          <Title>KONSTANTINO MYKONOS</Title>
          <Link
            href="https://www.google.com/maps/place/KONSTANTINO+JEWELRY+-+MYKONOS/@37.444966,25.3255058,17z/data=!3m1!4b1!4m5!3m4!1s0x14a2bf0c301477df:0x7070d7c505b2b4a!8m2!3d37.4449618!4d25.3276945"
            rel="noreferrer"
            target="_blank"
            underline="none"
            sx={{
              fontFamily: "Spartan-SemiBold",
              color: "#fff",
              fontSize: 14,
              lineHeight: 1.2,
              display: "inline-block",
              my: 1,
            }}
          >
            Enoplon Dynameon Str.
            <br />
            MYKONOS, GREECE
          </Link>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              "& *": {
                fontFamily: "Spartan-SemiBold",
                color: "#fff",
                fontSize: 14,
                lineHeight: 1.2,
              },
              "& a": {
                color: "#fff",
              },
            }}
          >
            <Box component="span">Tel:</Box>
            <Link href="tel:+302289023762" underline="none">
              +30 2289.023.762
            </Link>
            <Box component="span">Email:</Box>
            <Link
              href="mailto:mykonos@konstantino-jewelry.com"
              target="_blank"
              underline="none"
            >
              K-Mykonos
            </Link>
          </Stack>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          justifycontent="center"
          sx={{
            textAlign: "center",
          }}
        >
          <Title>KONSTANTINO US Contact Direct</Title>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              mt: 1,
              justifyContent: "center",
              alignItems: "center",
              "& *": {
                fontFamily: "Spartan-SemiBold",
                color: "#fff",
                fontSize: 14,
                lineHeight: 1.2,
              },
              "& a": {
                color: "#fff",
              },
            }}
          >
            {/* <Box component="span">Tel:</Box>
            <Link href="tel:+14144532857" underline="none">
              +1 414.453.2857
            </Link> */}
            <Box component="span">Email:</Box>
            <Link
              href="mailto:info@konstantino.com"
              underline="none"
              target="_blank"
            >
              K-U.S.A
            </Link>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}
