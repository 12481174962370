import {
  Box,
  Typography,
  Link,
  Container,
  Stack,
  Grid,
  TextField,
} from "@mui/material";
import { isMobile } from "react-device-detect";
import { Link as RouterLink } from "react-router-dom";

export default function StickyFooter(props) {
  const { component } = props;
  const filesUrl = component.getHelpers("env").get("new_files_url");

  const title = {
    color: "#fff",
    fontFamily: "GillSans",
    fontSize: { xs: 22, md: 24 },
    fontWeight: 700,
  };

  return (
    <Box
      id="page-footer"
      component="footer"
      sx={{
        mt: "auto",
        bgcolor: "#B5B6AD",
        py: 6,
      }}
    >
      <Container
        maxWidth="xl"
        disableGutters
        sx={{
          px: { xs: 2, sm: 6 },
        }}
      >
        <Grid container spacing={{ xs: 8, md: 0 }}>
          <Grid item xs={12} md={3}>
            <Stack
              spacing={1.5}
              sx={{
                alignItems: { xs: "center", md: "flex-start" },
                a: {
                  textDecoration: "none",
                  color: "#fff",
                  fontSize: 14,
                  fontFamily: "Spartan-SemiBold",
                  cursor: "pointer",
                },
              }}
            >
              <Typography sx={{ ...title }}>NEED HELP?</Typography>
              <Link component={RouterLink} to="/contact-us">
                Contact Us
              </Link>
              <Link component={RouterLink} to="/trunk-shows">
                Announcements
              </Link>
              <Link component={RouterLink} to="/assistance/shipping">
                Shipping
              </Link>
              <Link component={RouterLink} to="/assistance/faqs">
                FAQs
              </Link>
              <Link component={RouterLink} to="/assistance/returns">
                Returns
              </Link>
              <Link component={RouterLink} to="/assistance/care-tips">
                Care Tips
              </Link>
              <Link component={RouterLink} to="/signin">
                Login to your account
              </Link>
            </Stack>
            <Stack
              spacing={1.5}
              sx={{
                mt: 3,
                alignItems: { xs: "center", md: "flex-start" },
                a: {
                  textDecoration: "none",
                  color: "#fff",
                  fontSize: 14,
                  fontFamily: "Spartan-SemiBold",
                  cursor: "pointer",
                },
              }}
            >
              <Typography sx={{ ...title }}>LEGAL &amp; PRIVACY</Typography>
              <Link
                component={RouterLink}
                to="/assistance/privacy-policy-and-cookies"
              >
                Privacy & Cookies Policy
              </Link>
              <Link component={RouterLink} to="/assistance/warranty">
                Warranty
              </Link>
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack
              spacing={1.5}
              sx={{
                alignItems: { xs: "center", md: "flex-start" },
                a: {
                  textDecoration: "none",
                  color: "#fff",
                  fontSize: 14,
                  fontFamily: "Spartan-SemiBold",
                  cursor: "pointer",
                },
              }}
            >
              <Typography sx={{ ...title }}>K-WORLD</Typography>
              <Link component={RouterLink} to="/about">
                About
              </Link>
              <Link component={RouterLink} to="/legacy">
                Legacy
              </Link>
              <Link component={RouterLink} to="/films">
                Films
              </Link>
              <Link component={RouterLink} to="/limelight">
                Limelight
              </Link>
              <Link component={RouterLink} to="/press">
                Press
              </Link>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack
              spacing={0}
              sx={{
                alignItems: { xs: "center", md: "flex-start" },
              }}
              component="form"
              onSubmit={(e) => {
                e.preventDefault();
                component
                  .getApp()
                  .newsletterSubscribe(
                    component.getData("default.subscribe.email")
                  );
                component.setData({ "default.subscribe.email": "" });
              }}
            >
              <Typography sx={{ ...title }}>STAY IN TOUCH</Typography>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: 14,
                  fontFamily: "Spartan-SemiBold",
                  mt: 1.5,
                }}
              >
                Be the first to hear about new <br />
                designs, campaigns, and offers.
              </Typography>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: 14,
                  fontFamily: "Spartan-Bold",
                  mt: 2,
                }}
              >
                Subscribe To Our Newsletter
              </Typography>
              <TextField
                label="E-Mail Address"
                variant="standard"
                sx={{
                  "& .MuiInput-root": {
                    color: "#fff", // Text color
                    fontSize: 14,
                    fontFamily: "Spartan-Bold",
                    "&:before": {
                      borderBottomColor: "#fff", // Bottom border color
                    },
                    "&:hover:not(.Mui-disabled):before": {
                      borderBottomColor: "#fff", // Bottom border color on hover
                    },
                    "&:after": {
                      borderBottomColor: "#fff", // Bottom border color when focused
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "#fff", // Label color
                    fontSize: 14,
                    fontFamily: "Spartan-Bold",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#fff", // Label color when focused
                  },
                }}
                onChange={(e) =>
                  component.setData({
                    "default.subscribe.email": e.target.value,
                  })
                }
                value={component.getData("default.subscribe.email", "")}
              />
              {/* <FormControl
                variant="standard"
                focused={false}
                hiddenLabel={true}
                sx={{
                  backgroundColor: "#fff",
                  verticalAlign: "middle",
                  "& label.Mui-focused": {
                    color: "#fff",
                    borderRadius: 0,
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#fff",
                    borderRadius: 0,
                  },
                  "& .MuiOutlinedInput-input": {
                    px: 2,
                    py: 1,
                    fontSize: "12px",
                    borderColor: "#fff",
                  },
                  "& .MuiOutlinedInput-root": {
                    p: 0,
                    "& fieldset": {
                      borderColor: "#fff",
                      borderRadius: 0,
                    },
                    "&:hover fieldset": {
                      borderColor: "#fff",
                      borderRadius: 0,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#fff",
                      borderRadius: 0,
                    },
                  },
                }}
              >
                <OutlinedInput
                  type="text"
                  placeholder="E-Mail Address"
                  sx={{
                    "& .MuiInputBase-input": {
                      fontSize: 13,
                      color: "#fff",
                    },
                  }}
                  onChange={(e) =>
                    component.setData({
                      "default.subscribe.email": e.target.value,
                    })
                  }
                  value={component.getData("default.subscribe.email", "")}
                  endAdornment={
                    <InputAdornment position="end">
                      <Button
                        disableRipple
                        sx={{
                          textTransform: "none",
                          fontSize: 14,
                          borderLeft: "1px solid #fff",
                          borderRadius: 0,
                          height: "33px",
                          backgroundColor: "#fff",
                          px: 2,
                        }}
                        type="submit"
                      >
                        Next
                        <i
                          className="fa-light fa-arrow-right-long"
                          style={{ marginLeft: "5px" }}
                        ></i>
                      </Button>
                    </InputAdornment>
                  }
                />
              </FormControl> */}
            </Stack>
          </Grid>
          <Grid item xs={12} md={2}>
            <Stack
              spacing={1}
              sx={{
                alignItems: { xs: "center", md: "flex-start" },
              }}
            >
              <Typography sx={{ ...title }}>FOLLOW US</Typography>
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  alignItems: { xs: "center", md: "flex-start" },
                  a: {
                    textDecoration: "none",
                    color: "#fff",
                    fontSize: 22,
                    lineHeight: 1,
                  },
                }}
              >
                <Link
                  href="https://www.instagram.com/konstantinojewelry/"
                  target="_blank"
                  aria-label="instagram"
                >
                  <i className="fa-brands fa-instagram" />
                </Link>
                <Link
                  href="https://www.youtube.com/c/Konstantino"
                  target="_blank"
                  aria-label="youtube"
                >
                  <i className="fa-brands fa-youtube" />
                </Link>
                <Link
                  href="https://www.facebook.com/konstantinojewelry/"
                  target="_blank"
                  aria-label="facebook"
                >
                  <i className="fa-brands fa-facebook" />
                </Link>
                <Link
                  href="https://twitter.com/KONSTANTINO__"
                  target="_blank"
                  aria-label="twitter"
                >
                  <i className="fa-brands fa-x-twitter" />
                </Link>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack sx={{ alignItems: "center" }}>
              <Link
                component={RouterLink}
                to="/"
                sx={{ img: { width: { xs: 140, md: 200 } } }}
              >
                <img
                  src={`${filesUrl}/logos/white/square.png?tr=w-300`}
                  srcSet={`${filesUrl}/logos/white/square.png?tr=w-200 600w, ${filesUrl}/logos/white/square.png?tr=w-300 900w`}
                  sizes="(max-width: 600px) 140px, (max-width: 900px) 200px"
                  alt="Konstantino logo"
                />
              </Link>
              <Typography
                align="center"
                sx={{
                  color: "#fff",
                  fontSize: { xs: 11, md: 13 },
                  fontFamily: "Spartan-Bold",
                }}
              >
                Handcrafted in Athens, Greece
                <br />
                Derived by Greek tradition, elevated by design.
              </Typography>
              <Box
                sx={{
                  cursor: "pointer",
                  mt: 6,
                }}
                onClick={() => {
                  const el = document.getElementById(
                    "userwayAccessibilityIcon"
                  );
                  if (el) {
                    if (!isMobile) {
                      el.click();
                    } else {
                      var event1 = new Event("touchstart");
                      var event2 = new Event("touchend");
                      el.dispatchEvent(event1);
                      el.dispatchEvent(event2);
                    }
                  }
                }}
              >
                <img
                  // id="myAccessibilityIcon"
                  width="50"
                  height="50"
                  alt="Accessibility Widget"
                  src="https://cdn.userway.org/widgetapp/images/body_wh.svg"
                  data-uw-rm-ima-original="accessibility widget"
                ></img>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
