import React, { lazy, Suspense, useState, useEffect, useRef } from "react";
import { Box, CircularProgress, Skeleton, Fade } from "@mui/material";
import NavBarTest from "../widgets/navbar/navbar-test";
import MenuDrawer from "../../../Components/Widgets/MenuDrawer";
import SubMenuDrawer from "../../../Components/Widgets/SubMenuDrawer";
import Footer from "../widgets/footer";

const MainBanner = lazy(() =>
  import("../../default/widgets/sliders/main-banner")
);

const fadeTime = 500;

const ExportDefault = (props) => {
  const [visibleSections, setVisibleSections] = useState({
    mainBanner: false,
    footer: false,
  });

  const sectionRefs = useRef({
    mainBanner: null,
    footer: null,
  });

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1, // Trigger when 10% of the section is visible
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const sectionId = entry.target.id;
          setVisibleSections((prev) => ({ ...prev, [sectionId]: true }));
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    // Attach the observer to each section
    Object.keys(sectionRefs.current).forEach((key) => {
      if (sectionRefs.current[key]) {
        observer.observe(sectionRefs.current[key]);
      }
    });

    // Cleanup
    return () => {
      Object.keys(sectionRefs.current).forEach((key) => {
        if (sectionRefs.current[key]) {
          observer.unobserve(sectionRefs.current[key]);
        }
      });
    };
  }, []);

  return (
    <Suspense
      fallback={
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1200,
            bgcolor: "#000",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress sx={{ color: "#fff" }} size={80} thickness={1.5} />
          <Skeleton
            variant="rectangular"
            width="100%"
            height="100%"
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              bgcolor: "rgba(255,255,255,0.1)",
            }}
          />
        </Box>
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <NavBarTest bg_color="#fff" {...props} />
        <MenuDrawer {...props} />
        <SubMenuDrawer {...props} />
        <Fade in={visibleSections.mainBanner} timeout={fadeTime}>
          <div
            id="mainBanner"
            ref={(el) => (sectionRefs.current.mainBanner = el)}
          >
            <MainBanner
              {...{
                ...props,
                ...{
                  slide: {
                    slug: "main-banner",
                    title: "CLASSIC MIXED METALS",
                    desc: "Discover our signature style: sterling silver masterpieces enhanced by radiant touches of 18k gold that echo our legacy in every detail.",
                    descMaxWidth: "640px",
                    url: "/women/metal/mixed-metals",
                    buttons: [
                      {
                        name: "SHOP WOMEN’S",
                        url: "/women/metal/mixed-metals",
                      },
                      { name: "SHOP MEN’S", url: "/men/metal/mixed-metals" },
                    ],
                  },
                },
              }}
            />
          </div>
        </Fade>
        <Fade in={visibleSections.footer} timeout={fadeTime}>
          <div id="footer" ref={(el) => (sectionRefs.current.footer = el)}>
            <Footer {...props} />
          </div>
        </Fade>
      </Box>
    </Suspense>
  );
};

export default ExportDefault;
